import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import * as Schema from '@core_modules/customer/services/graphql/schema';
import { getLoginInfo } from '@helper_auth';

let isLogin = 0;
if (typeof window !== 'undefined') {
    isLogin = getLoginInfo();
}

const config = {
    context: {
        request: 'internal',
    },
};

// CHAT RELATED SCHEMA

export const getSessionMessageList = (options = {}) =>
    useQuery(Schema.getSessionMessageListSchema, {
        ...options,
        context: {
            request: 'internal',
        },
    });

export const addMessage = (options = {}) =>
    useMutation(Schema.addMessageSchema, {
        ...options,
        context: {
            request: 'internal',
        },
    });

export const createFirebaseDoc = (options = {}) =>
    useMutation(Schema.createFirebaseDocSchema, {
        ...options,
        context: {
            request: 'internal',
        },
    });

export const getMessageList = (options = {}) =>
    useLazyQuery(Schema.getMessageListSchema, {
        ...options,
        context: {
            request: 'internal',
        },
        fetchPolicy: 'network-only',
    });

export const getBlacklist = (options = {}) =>
    useQuery(Schema.getBlacklistSchema, {
        ...options,
        context: {
            request: 'internal',
        },
    });

// END CHAT RELATED SCHEMA

export const getRegions = () => useLazyQuery(Schema.getRegion);

export const getCountries = () => useLazyQuery(Schema.getCountries);

export const getCityByRegionId = (options = {}) => useLazyQuery(Schema.getCityByRegionId, { ...options, fetchPolicy: 'network-only' });

export const getCustomer = (otherConfig = {}) =>
    useQuery(Schema.getCustomer(otherConfig), {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
    });

export const getCustomerAddress = () =>
    useLazyQuery(Schema.getCustomerAddress, {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
    });

export const getCustomerOrder = () =>
    useQuery(Schema.getCustomerOrder, {
        context: {
            request: 'internal',
        },
    });

export const getCustomerSettings = () =>
    useQuery(Schema.getCustomerSettings, {
        context: {
            request: 'internal',
        },
    });

export const removeToken = () =>
    useMutation(Schema.removeToken, {
        context: {
            request: 'internal',
        },
    });

export const customerWishlist = (options) =>
    useLazyQuery(Schema.customerWishlist, {
        ...options,
        ...config,
    });

export const shareWishlist = (options = {}) =>
    useMutation(Schema.shareWishlist, {
        ...options,
        ...config,
    });

export const customerNotificationList = () =>
    useQuery(Schema.customerNotificationList, {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'network-only',
    });

export const getCmsBlocks = (variables, other = {}) =>
    useQuery(Schema.getCmsBlocks, {
        variables,
        context: {
            request: isLogin ? 'internal' : '',
        },
        fetchPolicy: isLogin ? 'network-only' : 'cache-first',
        skip: typeof window === 'undefined',
        ...other,
    });

export const getGiftCard = () =>
    useQuery(Schema.getGiftCard, {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
    });

export const checkBalance = (code) =>
    useQuery(Schema.checkBalance, {
        context: {
            request: 'internal',
        },
        variables: {
            gift_card_code: code,
        },
        skip: code === '' || !code,
    });

export const updatedDefaultAddress = (options = {}) =>
    useMutation(Schema.updatedDefaultAddress, {
        ...options,
        ...config,
    });

export const updateCustomerAddress = (options = {}) =>
    useMutation(Schema.updateCustomerAddress, {
        ...options,
        ...config,
    });

export const createCustomerAddress = (options = {}) =>
    useMutation(Schema.createCustomerAddress, {
        ...options,
        ...config,
    });

export const updateCustomer = (options = {}) =>
    useMutation(Schema.updateCustomer, {
        ...options,
        ...config,
    });

export const updateCustomerProfile = (options = {}) =>
    useMutation(Schema.updateCustomerProfile, {
        ...options,
        ...config,
    });

export const changeCustomerPassword = (options = {}) =>
    useMutation(Schema.changeCustomerPassword, {
        ...options,
        ...config,
    });

export const addSimpleProductsToCart = () =>
    useMutation(Schema.addSimpleProductsToCart, {
        context: {
            request: 'internal',
        },
    });

export const removeWishlist = () =>
    useMutation(Schema.removeWishlist, {
        context: {
            request: 'internal',
        },
    });

export const removeAddress = () =>
    useMutation(Schema.removeAddress, {
        context: {
            request: 'internal',
        },
    });

export const getCustomerCartId = () =>
    useLazyQuery(Schema.getCartIdUser, {
        context: {
            request: 'internal',
        },
        fetchPolicy: 'no-cache',
    });

export const reOrder = () =>
    useMutation(Schema.reOrder, {
        context: {
            request: 'internal',
        },
    });

export const newPassword = () =>
    useMutation(Schema.setNewPassword, {
        ...config,
    });

export const subscribeNewsletter = () => useMutation(Schema.subscribeNewsletter, { ...config });

export default {
    getCountries,
    getCityByRegionId,
    customerNotificationList,
    getCustomer,
    getCustomerOrder,
    reOrder,
};
